import React from 'react';
import SearchComponent from './components/SearchComponent';
import EmailSubscription from './components/EmailSignUp';
import Login from './components/Login';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import SpinnerLoader from './components/Spinner';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log("Current User", user);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false)
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  
  }, []);

  if (loading) {
    return (
      <div className='App'>
      <div className="spinner-loader-container">
        <SpinnerLoader />
      </div>
      </div>
    );
  }

  return (
    <div className="App">
    {user ? (
       <SearchComponent user={user} />     
          ) : (
            <div>
              <Login />
            </div>
          )}
    </div>  
  );
}

export default App;
