import React from 'react';
import { getAuth, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import './Login.css';

function Login() {
    const signInWithGoogle = () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider)
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className='container'>
            <a href="https://claims-market.com" target="_blank" rel="noopener noreferrer">
                <img src={'./ClaimsMarketLogo.png'} alt="Claims Market Logo" />
            </a>
            <h2>Subscribe for Updates</h2>
            <div className="icon-container">
                <a href="https://twitter.com/claims_market" target="_blank" rel="noopener noreferrer">
                    <img src="./Twitter.webp" alt="Icon 1" />
                </a>
                <a href="https://www.youtube.com/watch?v=Bv8QvHg0jNc" target="_blank" rel="noopener noreferrer">
                    <img src="./youtube.png" alt="Icon 2" />
                </a>
                <a href="https://www.linkedin.com/company/cherokee-acquisition/" target="_blank" rel="noopener noreferrer">
                    <img src="./linkedin.png" alt="Icon 3" />
                </a>
            </div>

            <button type="button" class="login-with-google-btn" onClick={signInWithGoogle}>
                Sign in with Google
            </button>
            <h3>
                Sign In to view and explore your FTX portfolio & claim size information
            </h3>
        </div>
    );
}

export default Login;


