import React, { useState } from 'react';
import { db } from './firebaseConfig'; // Import Firestore as db
import { getDoc, doc } from 'firebase/firestore'; // Firestore imports
import './SearchComponent.css';
import { setDoc, updateDoc } from 'firebase/firestore';

function processTokens(tokenString) {
  const tokens = tokenString.split(',').map(token => {
    const [name, quantity] = token.split('[');
    return {
      name: name.trim(),
      quantity: quantity ? quantity.replace(']', '').trim() : null
    };
  }).filter(token => parseFloat(token.quantity) !== 0);
  return tokens;
}

const fetchHistoricalPrice = async (symbol) => {
  console.log("Fetching price for token:", symbol);
  const TokenPricesDoc = doc(db, 'TokenPrices', symbol);
  const TokenPricesSnapshot = await getDoc(TokenPricesDoc);

  if (TokenPricesSnapshot.exists()) {
    const data = TokenPricesSnapshot.data();
    const price = data.price; // Convert to number
    console.log(`Fetched price for ${symbol}: ${price}`);
    return price;
  } else {
    console.log(`No data found for symbol: ${symbol}`);
    return "0";
  }
};

function calculateTotalClaimSize(tokens, prices) {
  let total = 0;
  for (let token of tokens) {
    const tokenPrice = prices[token.name] || 0; // Use 0 if the price is not available
    console.log("TOTALToken: " + token.quantity + " SPACE " + tokenPrice );

    // Check if both tokenPrice and token.quantity are valid numbers
    if (!isNaN(tokenPrice)) {
      total += token.quantity * parseFloat(tokenPrice); // Make sure to convert quantity to a number
    }

    console.log("TOTAL: " + total);
  }
  return total;
}


function SearchComponent(user) {
  const [customerNumber, setCustomerNumber] = useState('');
  const [customerData, setCustomerData] = useState(null);
  const [tokenPrices, setTokenPrices] = useState({});
  const [claimSize, setClaimSize] = useState(0);



  const handleSearch = async () => {
    console.log(user.user.email)
    // Assuming you have a Firestore instance initialized as db
    const customerRef = doc(db, 'ftxfilings', customerNumber);
    const customerDoc = await getDoc(customerRef);

    if (customerDoc.exists()) {
        const data = customerDoc.data();
        data.processedTokens = processTokens(data.TotalTokens);
        setCustomerData(data);

        const prices = {};
        for (let token of data.processedTokens) {
            try {
                const price = await fetchHistoricalPrice(token.name);
                prices[token.name] = price; // Convert to number
            } catch (error) {
                console.error("Failed to fetch prices for tokens", error);
                prices[token.name] = "0";
            }
        }

        setTokenPrices(prices); // Update the state for tokenPrices

        // Calculate the claim size using the updated prices
        const calculatedClaimSize = calculateTotalClaimSize(data.processedTokens, prices);
        setClaimSize(calculatedClaimSize); // Update the state for claimSize
    } else {
        setCustomerData(null);
    }

    const userRef = doc(db, 'users', user.user.email); // Assuming the user's email is unique and can be used as a document ID
    const userSnapshot = await getDoc(userRef);
    
    if (userSnapshot.exists()) {
      // If user already exists in the database, append the new customerRef to the existing array
      const currentSearches = userSnapshot.data().searches || [];
      const newSearch = {
          customerRef: customerNumber,
          timestamp: new Date().toISOString() // capturing the current time of the search
      };
      if (!currentSearches.some(search => search.customerRef === customerRef.path)) {
          // Only add if it doesn't already exist
          await updateDoc(userRef, {
              searches: [...currentSearches, newSearch]
          });
      }
  } else {
      // If user doesn't exist in the database, create a new entry
      await setDoc(userRef, {
          searches: [{
              customerRef: customerNumber,
              timestamp: new Date().toISOString()
          }]
      });
  }
  
    
};


  return (
    <div className="container">
      <a href="https://claims-market.com" target="_blank" rel="noopener noreferrer">
        <img src={'./ClaimsMarketLogo.png'} alt="Claims Market Logo" />
      </a>
      <h2>Subscribe for Updates</h2>
      <div className="icon-container">
      <a href="https://twitter.com/claims_market" target="_blank" rel="noopener noreferrer">
        <img src="./Twitter.webp" alt="Icon 1" />
      </a>
      <a href="https://www.youtube.com/watch?v=Bv8QvHg0jNc" target="_blank" rel="noopener noreferrer">
        <img src="./youtube.png" alt="Icon 2" />
      </a>
      <a href="https://www.linkedin.com/company/cherokee-acquisition/" target="_blank" rel="noopener noreferrer">
        <img src="./linkedin.png" alt="Icon 3" />
      </a>
      </div>
      <div className="search-box">
      <h1>Customer Search</h1>
        <input
          className="search-input"
          type="text"
          value={customerNumber}
          onChange={(e) => setCustomerNumber(e.target.value)}
          onKeyDown={(e) => { if (e.key === 'Enter') handleSearch(); }}
          placeholder="Enter Customer Number"
        />
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>
      {customerData && (
        <div className="results">
          <table className="customer-table">
            <tbody>
              <tr><td>Customer Number</td><td>{customerNumber}</td></tr>
              <tr><td>Page Number</td><td>{customerData.Page_Number || "N/A"}</td></tr>
              <tr><td>Docket Number</td><td>{customerData.Docket_Number || "N/A"}</td></tr>
              <tr><td>Claim Size</td>  <td>{claimSize ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claimSize) : "N/A"}</td></tr>
              <tr><td>Earn Indicator</td><td>{customerData.EarnIndicator || "NULL"}</td></tr>
              <tr><td>Lending Indicator</td><td>{customerData.Indicator || "NULL"}</td></tr>
              <tr><td>Token / Fiat in Lend</td><td>{customerData.TokenLend || "NULL"}</td></tr>
            </tbody>
          </table>
          <table className="tokens-table">
            <thead>
              <tr><th>Token Name</th><th>Quantity</th><th>Petition Date Values</th></tr>
            </thead>
            <tbody>
              {customerData.processedTokens.map((token, index) => 
              (
                <tr key={index}>
                  <td>{token.name}</td>
                  <td>{token.quantity}</td>
                  <td>{tokenPrices[token.name] || "Fetching"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SearchComponent;